<template>

  <div class="page">
    <div class="view">
      <el-table :data="cartList" style="padding:10px 10px;box-sizing: border-box;width: 100%;height: 100%;overflow: auto;">
        <el-table-column label="" >
          <template slot-scope="props">
            <div> <span>下单时间：{{ props.row.orderTime }} </span><span>采购编号：{{ props.row.no }}</span></div>
            <el-table border :data="props.row.orderItems">
              <el-table-column label="" prop="">
                <template slot-scope="scope">
                  {{ scope.row.goodsName }}
                </template>
              </el-table-column>
              <el-table-column label="" prop="">
                <template slot-scope="scope">
                  {{ scope.row.skuName }}
                </template>
              </el-table-column>
              <el-table-column label="" prop="">
                <template slot-scope="scope">
                  {{ scope.row.count }}
                </template>
              </el-table-column>
              <el-table-column label="" prop="">
                <template slot-scope="scope">
                  ￥{{ scope.row.price }}元
                </template>
              </el-table-column>
              <el-table-column label="" prop="">
                <template slot-scope="scope">
                  ￥{{ scope.row.price * scope.row.count }}元
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="props">
            <el-button type="text" size="mini" @click="Detail(props.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page :pageIndex="page.pageIndex" :pageSize="page.pageSize" :pageSizes="pageSizes" @size-change="handleSizeChange"
        :total="page.total" @change="changePage"></page>
    </div>
  </div>
</template>
<script>
import page from "../../components/page.vue"
export default {
  components: { page },
  data() {
    return {
      // 筛选
      cartList: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,

      },
      pageSizes: [10, 15, 30, 60],

    }
  },
  computed: {

  },
  mounted() {
    this.getcartList();
  },
  methods: {
    changePage(num) {
			this.page.pageIndex = num;
			this.getcartList()
		},
    Detail(row){
      console.log(row,'row');
      let query={
        id:row.id
      }
      this.$router.push({ path: 'purchaseDetail',query})
    },
    handleSizeChange(val) {
      this.page.pageIndex = 1;
      this.page.pageSize = val
      this.getcartList()
    },
    // 获取采购车
    getcartList() {
      let params = {
        "pageIndex": this.page.pageIndex,
        "pageSize": this.page.pageSize,
      }
      this.$http.post("/product/mall/shop/order/list", params).then(res => {
        if (res.code === 0) {
          console.log(res, 'res');
          this.cartList = res.data.items
          this.page.total = res.data.totalCount
        }
      })
    },

  },
}
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
  color: $fontColor;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px 50px;

  .comTitle {
    margin: 10px 5px;
    font-size: 18px;
  }

  .comView {
    width: 100%;
    background-color: #fff;
    border: 1px solid gainsboro;

    font-size: 15px;
  }

  .comItem {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;

  }

  .comView-left {
    width: 50%;
    line-height: 30px;

    div {
      display: flex;
    }
  }

  .view {
    overflow: hidden;
    flex: 1;
    background: #fff;
    border: 1px solid gainsboro;
    display: flex;
    flex-direction: column;

    .tableView {
      flex: 1;
    }
  }

  .viewBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }

  .viewBtn1 {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
</style>
